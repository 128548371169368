import { validateBr } from 'js-brasil';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { keycloak } from '../../lib/keycloakConfig';
import { KeycloakUserInfo, Warning } from '../../types/sso/sso';
import { sanitizeCpf } from '../../utils/sanitize-cpf';
import { useLoginMutation } from '../auth/authApiSlice';
import { logout, setCredentials } from '../auth/authSlice';
import { useVerifyUserMutation } from './ssoCallbackSlice';

import './style.css';

const password = process.env.REACT_APP_PASSWORD_CALLBACK ?? '';
const apiKey = process.env.REACT_APP_APIKEY ?? '';

export function SsoCallback() {
  const [login] = useLoginMutation();
  const [verifyUser] = useVerifyUserMutation();
  const dispatch = useDispatch();

  const [load, setLoad] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const handleLogin = useCallback(
    async (username: string) => {
      try {
        const isValidCpf = validateBr.cpf(username);

        const parsedUsername = isValidCpf ? sanitizeCpf(username) : username;
        const data = await login({
          username: parsedUsername,
          password,
          apiKey,
        }).unwrap();

        if (data?.token) {
          dispatch(setCredentials(data));

          navigate('/my-certifications', {
            state: { from: location },
            replace: true,
          });
        } else {
          console.error('Login error', data);
          keycloak.logout();
          dispatch(logout());
          setLoad(false);
        }
      } catch (error) {
        console.error('Login error', error);
      }
    },
    [login, dispatch, navigate, location],
  );

  useEffect(() => {
    const initKeycloak = async () => {
      try {
        dispatch(logout());
        setLoad(true);
        const authenticated = await keycloak.init({
          onLoad: 'login-required',
        });

        if (authenticated) {
          const userInfo = (await keycloak.loadUserInfo()) as KeycloakUserInfo;

          const data = await verifyUser({
            _id: userInfo?.sub,
          });

          if ('data' in data && data?.data?.enrollUrl) {
            window.location.href = data?.data.enrollUrl;
            setLoad(false);
            return;
          }

          if ('data' in data && data?.data?.validatedCpf) {
            handleLogin(data?.data?.validatedCpf);
            return;
          }

          const errors =
            'data' in data &&
            data?.data?.errors?.filter(
              error => error.field === 'cnpj' || error.field === 'dadosCert',
            );

          if (errors) {
            errors?.map((error: Warning) =>
              toast.error(error?.message, {
                position: 'top-center',
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              }),
            );

            setLoad(false);
            return;
          }

          toast.error('Ops! Ocorreu um erro inesperado.', {
            position: 'top-center',
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          setLoad(false);
        } else {
          dispatch(logout());
          setLoad(false);
        }
        dispatch(logout());
      } catch (error) {
        setLoad(false);
        dispatch(logout());
        console.error('Keycloak initialization error', error);
        console.error(error);
      }
    };
    initKeycloak();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return load ? (
    <div className="absolute w-full h-screen flex items-center justify-center bg-black/[.7]">
      <div className="loader ease-linear rounded-full border-8  h-32 w-32" />
    </div>
  ) : null;
}
