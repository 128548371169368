// import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

import { apiSlice } from '../../app/api/apiSlice';
import { VerifyUser } from '../../types/sso/sso';

export const signUpApiSlice = apiSlice.injectEndpoints({
  endpoints: ({ mutation }) => ({
    verifyUser: mutation<VerifyUser, { _id: string }>({
      // @ts-expect-error
      async queryFn({ _id }, _api, _extraOptions, baseQuery) {
        const userInfo = await baseQuery({
          url: 'database/get_candidato_bradesco__c',
          method: 'PUT',
          headers: {
            Authorization: process.env.REACT_APP_FUNIFIER_VERIFY_PLAYER_TOKEN,
          },
          body: { _id },
        });

        return userInfo;
      },
    }),
  }),
});

export const { useVerifyUserMutation } = signUpApiSlice;
