/* eslint-disable @typescript-eslint/no-explicit-any */
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { Buffer } from 'buffer';

import { apiSlice } from '../../app/api/apiSlice';
import {
  AuthState,
  FunifierPlayerProvider,
  Providers,
  RecoveryPasswordCodeResponse,
  RecoveryPasswordResponse,
} from '../../types/auth/AuthState';
import { CertificationResponse } from '../../types/auth/CertificationResponse';
import { Token } from '../../types/auth/Token';
import { User } from '../../types/auth/User';
import { getRegistrationToken } from '../../utils/getRegistrationToken';
import { sanitizeCpf } from '../../utils/sanitize-cpf';

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    hasPlayer: builder.mutation<Providers[], { username: string }>({
      async queryFn({ username }, _api, _extraOptions, baseQuery) {
        const result = await baseQuery({
          url: `database/find_player_route__c`,
          method: 'PUT',
          headers: {
            Authorization: process.env.REACT_APP_FUNIFIER_FIND_PROVIDER_TOKEN,
          },
          body: {
            _id: username,
          },
        });

        const data = result.data as FunifierPlayerProvider;

        const parsedData = data.routes.map(route => ({
          value: route._id,
          label: route.name,
          openId: route.openId,
        }));

        return data
          ? {
              data: parsedData,
            }
          : { error: result.error as FetchBaseQueryError };
      },
    }),

    login: builder.mutation<
      AuthState,
      { username: string; password: string; apiKey?: string }
    >({
      async queryFn(
        { username, password, apiKey },
        _api,
        _extraOptions,
        baseQuery,
      ) {
        const authDada = await baseQuery({
          url: 'auth/token',
          method: 'POST',
          body: {
            apiKey: apiKey ?? process.env.REACT_APP_APIKEY,
            username,
            password,
            grant_type: 'password',
          },
        });

        const authStateData = authDada.data as Token;

        const result = await baseQuery({
          url: 'player/me',
          method: 'GET',
          headers: {
            Authorization: `Bearer ${authStateData?.access_token}`,
          },
        });

        const data = result.data as User;

        return data
          ? {
              data: {
                token: authStateData,
                user: {
                  _id: data?._id,
                  email: data?.email,
                  name: data?.name,
                  image: data?.image,
                  extra: data?.extra,
                  apiKey: apiKey ?? process.env.REACT_APP_APIKEY,
                },
              } as AuthState,
            }
          : { error: result.error as FetchBaseQueryError };
      },
    }),

    validateCertification: builder.mutation<
      CertificationResponse[],
      { cpf: string }
    >({
      async queryFn({ cpf }, _api, _extraOptions, baseQuery) {
        const sanitizedCPF = sanitizeCpf(cpf);

        const result: any = await baseQuery({
          url: `database/certification_log__c/aggregate?strict=true`,
          method: 'POST',
          headers: {
            Authorization: getRegistrationToken(),
          },
          body: [
            {
              $match: {
                status: '4',
                player: sanitizedCPF,
              },
            },
            {
              $lookup: {
                from: 'player',
                localField: 'player',
                foreignField: '_id',
                as: 'p',
              },
            },
            {
              $unwind: '$p',
            },
            {
              $lookup: {
                from: 'certification__c',
                localField: 'certification',
                foreignField: '_id',
                as: 'c',
              },
            },
            {
              $unwind: '$c',
            },
            {
              $project: {
                candidato: '$p.name',
                certification: '$c.title',
                started: 1,
                certification_validity: {
                  $dateAdd: {
                    startDate: '$started',
                    unit: 'year',
                    amount: '$c.certification_validity',
                  },
                },
              },
            },
          ],
        });
        let data = [] as CertificationResponse[];

        if (result.data.length > 0) {
          data = result.data.map((item: any) => ({
            _id: item._id,
            candidato: item.candidato,
            certification: item.certification,
            certification_validity: item.certification_validity.$date,
          })) as CertificationResponse[];
        }

        const oldAssbanResult = await fetch(
          `https://consulta.assbandfcertifica.com.br/${cpf}`,
        );
        const oldAssbanData = await oldAssbanResult.json();

        if (oldAssbanData.length > 0) {
          const oldAssbanCertifications = oldAssbanData.map((item: any) => ({
            _id: item.dateApproved,
            candidato: item.name,
            certification: item.certificationTitle,
            certification_validity: item.expirationDate,
          }));

          data.push(...oldAssbanCertifications);
        }

        return data
          ? {
              data: data as CertificationResponse[],
            }
          : { error: result.error as FetchBaseQueryError };
      },
    }),

    getRecoveryPasswordCode: builder.mutation<
      RecoveryPasswordCodeResponse,
      { username: string; apiKey: string }
    >({
      async queryFn({ username, apiKey }, _api, _extraOptions, baseQuery) {
        const token = Buffer.from(`${apiKey}:`).toString('base64');

        const result = await baseQuery({
          url: `player/password/change?player=${username}`,
          method: 'GET',
          headers: {
            Authorization: `Basic ${token}`,
          },
        });

        const data = result.data as RecoveryPasswordCodeResponse;

        return data
          ? {
              data: {
                sent_to: data?.sent_to,
                message: data?.message,
              } as RecoveryPasswordCodeResponse,
            }
          : { error: result.error as FetchBaseQueryError };
      },
    }),

    recoveryPasswordCode: builder.mutation<
      RecoveryPasswordResponse,
      { username: string; code: string; password: string; apiKey: string }
    >({
      async queryFn(
        { username, code, password, apiKey },
        _api,
        _extraOptions,
        baseQuery,
      ) {
        const token = Buffer.from(`${apiKey}:`).toString('base64');

        const result = await baseQuery({
          url: `player/password?player=${username}&code=${code}&new_password=${password}&api_key=${apiKey}`,
          method: 'PUT',
          headers: {
            Authorization: `Basic ${token}`,
          },
        });

        const data = result.data as RecoveryPasswordResponse;

        return data
          ? {
              data: {
                message: data?.message,
                code: data?.code,
                type: data?.type,
              } as RecoveryPasswordResponse,
            }
          : { error: result.error as FetchBaseQueryError };
      },
    }),
  }),
});

export const {
  useHasPlayerMutation,
  useLoginMutation,
  useValidateCertificationMutation,
  useGetRecoveryPasswordCodeMutation,
  useRecoveryPasswordCodeMutation,
} = authApiSlice;
